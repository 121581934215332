import React, { useState, useEffect, memo } from "react";
import emailjs from "@emailjs/browser";
import { Camera, Download, Settings2, Coffee } from "lucide-react";

// Componente memoizado para anúncios do Google AdSense
const AdUnit = memo(({ adSlot }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const checkAdSenseScript = () => {
      if (window.adsbygoogle) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          setIsScriptLoaded(true);
        } catch (e) {
          console.error("AdSense error:", e);
          setIsScriptLoaded(false);
        }
      }
    };

    checkAdSenseScript();
    const timer = setInterval(checkAdSenseScript, 3000);

    return () => clearInterval(timer);
  }, []);

  if (!isScriptLoaded) {
    return (
      <div className="w-full my-4 bg-gray-100 border border-gray-300 text-center py-4">
        Advertisement Loading...
      </div>
    );
  }

  return (
    <div key={adSlot} className="w-full my-4">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-1260763084605199"
        data-ad-slot={adSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
});

const QRGenerator = () => {
  const [text, setText] = useState("");
  const [qrSize, setQrSize] = useState(200);
  const [emailStatus, setEmailStatus] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [generatedFormat, setGeneratedFormat] = useState(null);

  useEffect(() => {
    // Initialize EmailJS with your PUBLIC KEY
    emailjs.init("jtrMNyjyJn_KSD5x4");

    // Função para adicionar script com verificação
    const addScript = (src, attributes = {}, onLoadCallback = null) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      // Adicionar atributos personalizados
      Object.keys(attributes).forEach((key) => {
        script.setAttribute(key, attributes[key]);
      });

      // Adicionar manipuladores de erro e carregamento
      script.onerror = () => console.error(`Failed to load script: ${src}`);

      if (onLoadCallback) {
        script.onload = onLoadCallback;
      }

      document.head.appendChild(script);
      return script;
    };

    // Adicionar scripts de forma segura
    const adSenseScript = addScript(
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1260763084605199",
      { crossorigin: "anonymous" }
    );

    // Google Analytics Scripts
    const analyticsScript1 = addScript(
      "https://www.googletagmanager.com/gtag/js?id=G-JW4NGHT4VG",
      {},
      () => {
        // Garantir que gtag seja definido após o carregamento do script
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-JW4NGHT4VG");

        console.log("Google Analytics initialized");
      }
    );

    // Cleanup function
    return () => {
      try {
        document.head.removeChild(adSenseScript);
        document.head.removeChild(analyticsScript1);
      } catch (error) {
        console.warn("Error during script cleanup:", error);
      }
    };
  }, []);

  const getBrowserInfo = () => {
    const ua = navigator.userAgent;
    if (ua.match(/chrome|chromium|crios/i)) return "Chrome";
    if (ua.match(/firefox|fxios/i)) return "Firefox";
    if (ua.match(/safari/i)) return "Safari";
    if (ua.match(/opr\//i)) return "Opera";
    if (ua.match(/edg/i)) return "Edge";
    return "Unknown";
  };

  const getOSInfo = () => {
    const ua = window.navigator.userAgent;
    if (ua.indexOf("Windows") !== -1) return "Windows";
    if (ua.indexOf("Mac") !== -1) return "MacOS";
    if (ua.indexOf("Linux") !== -1) return "Linux";
    if (ua.indexOf("Android") !== -1) return "Android";
    if (ua.indexOf("iOS") !== -1) return "iOS";
    return "Unknown";
  };

  const trackQRGeneration = async (format) => {
    try {
      // Google Analytics event for QR Code generation
      if (window.gtag) {
        window.gtag("event", "generate_qr", {
          event_category: "QR Code",
          event_label: format,
          value: text ? text.length : 0,
        });
      }

      setEmailStatus("sending");
      console.log("Iniciando envio de email:", { format, text });

      // Validação de dados
      if (!text) {
        console.error("Texto vazio, não pode enviar email");
        setEmailStatus("error");
        return;
      }

      // Fetch geolocation com tratamento de erros mais robusto
      let geoData = {};
      try {
        const geoResponse = await fetch("https://ipapi.co/json/", {
          timeout: 5000,
        });

        if (!geoResponse.ok) {
          throw new Error(`Erro na geolocalização: ${geoResponse.status}`);
        }

        geoData = await geoResponse.json();
      } catch (geoError) {
        console.warn("Erro detalhado na geolocalização:", {
          message: geoError.message,
          name: geoError.name,
          stack: geoError.stack,
        });
      }

      const templateParams = {
        text: text || "Sem texto",
        format: format || "Formato desconhecido",
        timestamp: new Date().toLocaleString(),
        userAgent: navigator.userAgent || "Unknown",
        screenSize: `${window.innerWidth}x${window.innerHeight}`,
        ip: geoData?.ip || "Unknown",
        country: geoData?.country_name || "Unknown",
        region: geoData?.region || "Unknown",
        city: geoData?.city || "Unknown",
        browser: getBrowserInfo(),
        os: getOSInfo(),
        language: navigator.language || "Unknown",
      };

      console.log("Parâmetros completos do email:", templateParams);

      try {
        const result = await emailjs.send(
          "service_6s3cuqd",
          "template_3fa6jcb",
          templateParams,
          "jtrMNyjyJn_KSD5x4"
        );

        console.log("Resultado detalhado do envio:", {
          status: result.status,
          text: result.text,
          fullResponse: result,
        });

        if (result.status === 200) {
          setEmailStatus("success");
          console.log("Email enviado com sucesso!");
        } else {
          throw new Error(`Falha no envio do email: Status ${result.status}`);
        }
      } catch (sendError) {
        console.error("Erro completo no envio:", {
          message: sendError.message,
          name: sendError.name,
          stack: sendError.stack,
          responseText: sendError.responseText,
          responseStatus: sendError.status,
        });
        setEmailStatus("error");
      }

      // Reset status after 3 seconds
      setTimeout(() => setEmailStatus(null), 3000);
    } catch (error) {
      console.error("Erro fatal:", {
        message: error.message,
        name: error.name,
        stack: error.stack,
      });
      setEmailStatus("error");
    }
  };

  const generateQR = async (format) => {
    if (!text) return;

    try {
      // Define o formato gerado
      setGeneratedFormat(format);

      // Envia email de notificação
      await trackQRGeneration(format);
    } catch (error) {
      console.error("Erro ao gerar QR Code:", error);
    }
  };

  const getQRCode = (format = "svg") => {
    const baseUrl = "https://api.qrserver.com/v1/create-qr-code/";
    const params = new URLSearchParams({
      data: text,
      size: `${qrSize}x${qrSize}`,
      format: format,
    });
    return `${baseUrl}?${params.toString()}`;
  };

  const downloadQR = async (format) => {
    if (!text) return;

    try {
      // Google Analytics event for QR Code download
      if (window.gtag) {
        window.gtag("event", "download_qr", {
          event_category: "QR Code",
          event_label: format,
        });
      }

      // Primeiro, gera o QR Code (se ainda não foi gerado)
      if (generatedFormat !== format) {
        await generateQR(format);
      }

      // Depois, faz o download
      const link = document.createElement("a");
      link.href = getQRCode(format);
      link.download = `qr-code.${format}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao processar download:", error);
    }
  };

  const handleDonation = () => {
    // Google Analytics event for donation button click
    if (window.gtag) {
      window.gtag("event", "donation_click", {
        event_category: "Donation",
        event_label: "Buy Me a Coffee",
      });
    }

    window.open("https://www.paypal.com/paypalme/mpsf2024/2", "_blank");
  };

  if (hasError) {
    return (
      <div className="text-center p-8">
        <h1 className="text-2xl text-red-600">Erro ao carregar a aplicação</h1>
        <p>Por favor, recarregue a página ou tente novamente mais tarde.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Camera className="h-8 w-8 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-900">QR Now!</h1>
          </div>
          <Settings2 className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-700" />
        </div>
      </header>

      {/* Top Ad */}
      <div className="max-w-7xl mx-auto px-4 py-4">
        <AdUnit adSlot="1234567890" />
      </div>

      <div className="max-w-7xl mx-auto px-4 text-center">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          🚀 Generate QR Codes Instantly!
        </h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto leading-relaxed">
          Welcome to QR Now! ✨ Create unlimited QR codes completely free,
          forever! Perfect for businesses, personal use, or sharing information
          quickly. Download in multiple formats (SVG, PNG, JPG) and customize
          sizes to fit your needs. No registration required! 🎯 Start creating
          your QR codes now! 🎨
        </p>
      </div>

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Enter text or URL
              </label>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-32"
                placeholder="Enter the text you want to convert to QR code..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                QR Code Size: {qrSize}x{qrSize}
              </label>
              <input
                type="range"
                min="100"
                max="400"
                value={qrSize}
                onChange={(e) => setQrSize(e.target.value)}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
            </div>

            <div className="flex flex-wrap gap-4">
              <button
                onClick={() => generateQR("svg")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                disabled={!text}
              >
                Generate SVG
              </button>
              <button
                onClick={() => downloadQR("svg")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                disabled={!text}
              >
                <Download className="h-4 w-4 mr-2" />
                Download SVG
              </button>

              <button
                onClick={() => generateQR("png")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
                disabled={!text}
              >
                Generate PNG
              </button>
              <button
                onClick={() => downloadQR("png")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
                disabled={!text}
              >
                <Download className="h-4 w-4 mr-2" />
                Download PNG
              </button>

              <button
                onClick={() => generateQR("jpg")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700"
                disabled={!text}
              >
                Generate JPG
              </button>
              <button
                onClick={() => downloadQR("jpg")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700"
                disabled={!text}
              >
                <Download className="h-4 w-4 mr-2" />
                Download JPG
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              {text && generatedFormat && (
                <img
                  src={getQRCode(generatedFormat)}
                  alt="Generated QR Code"
                  className="max-w-full h-auto"
                />
              )}
              {(!text || !generatedFormat) && (
                <div className="w-[200px] h-[200px] flex items-center justify-center text-gray-400 border-2 border-dashed rounded">
                  QR Preview
                </div>
              )}
            </div>

            <div className="text-center mt-4 space-y-3">
              <p className="text-sm text-gray-500">
                Love this free tool? Help keep it running! ❤️
              </p>
              <div className="flex items-center justify-center space-x-4">
                <button
                  onClick={handleDonation}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 transition-colors"
                >
                  <Coffee className="h-4 w-4 mr-2" />
                  Buy me a coffee (2€)
                </button>
              </div>
              {emailStatus === "sending" && (
                <p className="text-xs text-blue-500">Sending notification...</p>
              )}
              {emailStatus === "success" && (
                <p className="text-xs text-green-500">Notification sent!</p>
              )}
              {emailStatus === "error" && (
                <p className="text-xs text-red-500">
                  Error sending notification
                </p>
              )}
              <p className="text-xs text-gray-400">
                Supporting our ads helps keep this service free for everyone
              </p>
            </div>
          </div>
        </div>
      </main>

      {/* Bottom Ad */}
      <div className="max-w-7xl mx-auto px-4 py-4">
        <AdUnit adSlot="0987654321" />
      </div>

      <footer className="bg-white mt-8">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <p className="text-center text-gray-500 text-sm">
            © 2024 QR Now! - Generate QR codes instantly
          </p>
        </div>
      </footer>
    </div>
  );
};

export default QRGenerator;
